// var vueElements = vueElements || {};
//
// (function($){
// 	$(function(){
// 		//set the selector where we start
// 		var rootElem = '#birthday-control';
//
// 		//only fire up when the element exists
// 		if($(rootElem).length > 0) {
//
// 			//create a vue Element
// 			vueElements.default = new Vue({
// 				el: rootElem,
// 				mounted: function () {
//
// 					var self = this;
//
// 					//show element
// 					$(rootElem).show();
//
// 					//fetch the current data
// 					self.fetchData('/items/ajax');
// 				},
// 				data: {
// 					items: {}
// 				},
// 				// define methods under the `methods` object
// 				methods: {
// 					fetchData: function(url){
// 						var self = this;
//
// 						//fallback
// 						url = url || '/items/ajax';
//
// 						//show the loader
// 						//$('body').addClass('show-loader');
//
// 						//get the data
// 						$.get(url, {}, function(data){
// 							//parse the data
// 							data = JSON.parse(data);
//
// 							//set the data
// 							self.items = data;
//
// 							//hide the loader
// 							//$('body').removeClass('show-loader');
//
// 						});
// 					},
// 					sort: function(items){
// 						return _.sortBy(items, ['day']);
// 					}
// 				}
// 			});
// 		}
// 	});
// })(jQuery);