var vueElements = vueElements || {};

(function($){
	$(function(){
		//set the selector where we start
		var rootElem = '#calendar-control';

		//only fire up when the element exists
		if($(rootElem).length > 0) {

			//create a vue Element
			vueElements.CalendarControl = new Vue({
				el: rootElem,
				mounted: function () {

					var self = this;

					//show element
					$(rootElem).show();

					self.limit = $(rootElem).data('limit');

					//fetch the current data
					self.fetchData('/agenda-items/ajax');
				},
				data: {
					limit: 0,
					items: {}
				},
				// define methods under the `methods` object
				methods: {
					fetchData: function(url){
						var self = this;

						//fallback
						url = url || '/agenda-items/ajax';

						//show the loader
						$('body').addClass('show-loader');

						//get the data
						$.get(url, {limit: self.limit}, function(data){
							//parse the data
							data = JSON.parse(data);

							//set the data
							self.items = data;

							//hide the loader
							$('body').removeClass('show-loader');

						});
					}
				}
			});
		}
	});
})(jQuery);