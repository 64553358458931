var vueElements = vueElements || {};

(function($){
	$(function(){
		//set the selector where we start
		var rootElem = '#parent-talks-control';

		//only fire up when the element exists
		if($(rootElem).length > 0) {

			//create a vue Element
			vueElements.ParentTalksControl = new Vue({
				el: rootElem,
				mounted: function () {

					var self = this;

					//only run if the ID is found
					if($('#oudergesprekModal').length > 0) {

						$('#oudergesprekModal').on('show.bs.modal', function (e) {
							self.reset();
						});

						//get the options for the groups
						$.get('/oudergesprekken/ajax_groepen', function(data){

							//assign the data to the HTML
							$('#oudergesprekModal #klasChoice').append(data);
							customSelect();
							handleSelect();

							//when a group has been choosen
							$('#oudergesprekModal input[name="klasChoice"]').change(function(e){
								//save the data
								self.groupEntryId = $(this).val();

								//only go further if the entry id is catched
								if(self.groupEntryId !== '') {
									//show the data HTML
									$('#parent-talks-data').show();

									//hide global error
									self.hideGlobalError();

									//hide the personal data
									$('#parent-talks-detail-form').hide();

									//disable the button
									self.toggleSubmitButton(false);

									//get the dates for the group
									$.get('/oudergesprekken/ajax_data?entryId='+self.groupEntryId, function(data){
										self.items = JSON.parse(data);
									});
								} else {
									self.reset();
								}
							});
						});
					}
				},
				data: {
					items: {},
					selectedItem: null,
					emailOuder: '',
					naamKind: '',
					groupEntryId: ''
				},
				// define methods under the `methods` object
				methods: {
					select: function(item){
						var self = this;
						if(!item.booked) {
							//remove other selected
							this.removeSelected();

							//select this item
							item.selected = true;

							//alias it
							self.selectedItem = item;

							//hide global error
							self.hideGlobalError();

							//hide global succes
							self.hideGlobalSuccess();

							//show the form
							$('#parent-talks-detail-form').show();

							//activate submit button
							self.toggleSubmitButton(true);
						}
					},

					save: function(){
						var self = this;

						if(!self.selectedItem.booked) {
							var valid = true;
							var $errorKind = $('#naamKind').parents('.input-wrapper').find('.formError');
							var $errorEmailOuder = $('#emailOuder').parents('.input-wrapper').find('.formError');

							//check the required fields
							if(self.naamKind === '') {
								valid = false;
								$errorKind.show();
								$errorKind.find('.error_msg').text('U dient de naam van uw kind op te geven.');
							} else {
								$errorKind.hide();
							}

							if(self.emailOuder === '') {
								valid = false;
								$errorEmailOuder.show();
								$errorEmailOuder.find('.error_msg').text('U dient uw eigen naam op te geven');
							} else if(!self.validateEmail(self.emailOuder)) {
								valid = false;
								$errorEmailOuder.show();
								$errorEmailOuder.find('.error_msg').text('Het opgegeven e-mailadres is onjuist.');
							} else {
								$errorEmailOuder.hide();
							}

							if(valid) {

								//disable the button and add the loader;
								self.toggleSubmitButton(false, true);
								$.get('/oudergesprekken/ajax_save', {naamKind: self.naamKind, emailOuder: self.emailOuder, rowId: self.selectedItem.rowId}, function(result){

									//parse the result back
									result = JSON.parse(result);

									if(result.success) {
										//set a timer, so you get the feeling it does something
										setTimeout(function(){
											//show the message
											self.showGlobalSuccess(result.message);
											//reset all the data
											self.reset();
										}, 500);

										setTimeout(function(){
											//hide modal
											$('#oudergesprekModal').modal('hide');
											self.hideGlobalSuccess();
										}, 4000);
									} else {
										//activate the button
										self.toggleSubmitButton(true);

										// if the date is not available, set the item as inactive
										if(result.code === 1) {
											self.selectedItem.booked = true;
										//remove the item, because its not foun
										} else if(result.code === 2) {
											self.removeItem(self.selectedItem.rowId);
										}

										//show the message
										self.showGlobalError(result.message);
									}
								});
							}
						}

					},

					removeSelected: function(){
						var self = this;
						_.each(self.items, function(item){
							item.selected = false;
						});
					},

					validateEmail: function(email) {
						var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return re.test(String(email).toLowerCase());
					},

					toggleSubmitButton: function(active, withLoader) {
						active = active || false;
						withLoader = withLoader || false;

						if(active) {
							$('#saveParentMeeting').removeClass('btn-disabled');
							$('#saveParentMeeting #parent-talks-btn-loader').hide();

						} else if(!active) {
							$('#saveParentMeeting').addClass('btn-disabled');
							if(withLoader) {
								$('#saveParentMeeting #parent-talks-btn-loader').show();
							} else {
								$('#saveParentMeeting #parent-talks-btn-loader').hide();
							}
						}
					},

					reset: function(){
						var self = this;

						//hide the form
						$('#parent-talks-detail-form').hide();
						$('#parent-talks-data').hide();

						//disable the submit button
						self.toggleSubmitButton(false, false);

						//Reset other values
						self.groupEntryId = '';
						self.emailOuder = '';
						self.naamKind = '';

						//reset custom select
						// $('#klasChoice li:first').click(function(){
						// 	$(this).click();
						// });
					},
					showGlobalError: function(message){
						$('.parent-talk-global-error').text(message).show();
					},
					showGlobalSuccess: function(message){
						$('.parent-talk-global-success').text(message).show();
					},
					hideGlobalError: function(){
						$('.parent-talk-global-error').text('').hide();
					},
					hideGlobalSuccess: function(){
						$('.parent-talk-global-success').text('').hide();
					},
					removeItem: function(rowId){
						var self = this;
						self.items = _.remove(self.items, function(n) {
							return n.rowId !== rowId;
						});

						self.selectedItem = null;
					}
				}
			});
		}
	});
})(jQuery);